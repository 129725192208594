import React, { useState, useContext, useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount, useSigner } from "wagmi";
import CloudContracts from "../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { calculateZeroes, currentEthPrice, usdToEth } from "../../1.resources/2.js/0.global/0.smallfunctions/currencyConversion";
import { GlobalParams } from "../0.wrapper/darkMode";
import { teamAddress, zeroAddress } from "../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import InfiniteScroll from 'react-infinite-scroll-component';
import { collection, onSnapshot, orderBy, query, where, limit, startAfter, getDocs, startAt } from "firebase/firestore";
import { db } from "../../1.resources/2.js/0.global/1.firebase/firebase";
import { timeToString } from "../../1.resources/2.js/0.global/0.smallfunctions/time";
import { shortenaddress } from "../../1.resources/2.js/0.global/0.smallfunctions/global";
import { callW3Api, getDomain } from "../../1.resources/2.js/0.global/3.api/callW3Api";
import makeBlockie from "ethereum-blockies-base64";
import { Variables } from "../../1.resources/2.js/0.global/2.contracts/variables";
import { ethers } from "ethers";
import AddReserve from "./partials/add";
import EditReserve from "./partials/edit";

const Reserve = ({ }) => {
    const [tabSelected, setTabSelected] = useState("reserved");
    const [reservedTotal, setReservedTotal] = useState(0);
    const { data: signer } = useSigner()
    const [addModal, setAddModal] = useState(false);
    async function init() {

    }

    useEffect(() => {
        init();
    }, [])

    async function airdrop() {
        // let result = await callW3Api("/admin/get/reserve", { type: "airdrops" });

        // console.log(result);

        let json = `jetsetj	0xe2A3513936F0C52bBdDD45c2a2CE64675d59028b
        Lawyer	0x963558ea26615128A475569bCCd5CFd74104cF91
        American	0x58ff6950ecf6521729addc597f50d0405fdb2652
        Martymc	0x6219DEB47124dEf3F39385eFF794F00033798e32
        CaptainTrippy	0x37ddc28feae0ac090661c4e7e24989ff4e9aea43
        Wyb0	0x1b6284B63ad8e0b0E254500869896153A2260D1c
        Gator	0x56cb7C468adF7706a7a9f0Ef168F935C796EDEC8
        cool	0xCaE8047a338ab36537F51794ccF4C4756694C214
        FORGE	0xb6F2D1532d41d306e83B7993d4d6C0b3ef6F67Af
        William	0xc8b0D32bc09Fb11C12C82582825C1e6b624822b8
        0xchar	0x599Fb9dAFB6224eF4b0123533032722B1bA4A3B9
        Char	0x599Fb9dAFB6224eF4b0123533032722B1bA4A3B9
        ray	0x47b7CdE1C9853Ad77d5009956962211651b40B62
        9gagceo	0x47b7CdE1C9853Ad77d5009956962211651b40B62
        Alex	0x000000Dd087C1010B14418D25B6Ed98f3119f0C5
        herb	0x2404D6857d7E90c3eD9E74E0d372a4d6d7399504
        badteeth	0xFeeD2eab7fc2c43D06f55eCD5ef5DB5f2fE77935
        pizza	0x06B678AC37940aF0d4359eC10abd2c14615df83e
        raw	0x374b1CCd387b9F09641AE89aCa45CA6EcABeD6CE
        Shmoo	0xd930b8446282ab217b8D63eB3C2c3630F8ABF424
        BenJammin	0xA41A4b84D74E085bd463386d55c3b6dDe6aa2759
        NFTgod	0x3859F6cC6cD83c0FDF2dbAD2a19E39C41405E22a
        ngbshpend	0xd23b06012790E2D8E01a7607127588b609C27f0C
        MD3K	0x906b346A7d88d8EC5EB0749fEd160702f58BF425
        kanto	0x267dD9996AD759378Fe34DAE76F74b5bd2e5155F
        Cole	0xC2827C709fA31404a623a1BBc6206F14acEeaFED
        ThreadGuy	0x95Ed0B8aF5400452a12cd47F130175335e46e732
        garga	0x2474A7227877F2b65185F09468AF7C6577FA207C
        thank	0x96c41aa08D8efFdfD86697F6Dece1Bb889B27004
        McRayJohn	0x0832221806df52ff6759ff283cd47eb52b86c2fa
        Vitalik	0xab5801a7d398351b8be11c439e05c5b3259aec9b
        boredcentral	0xB036E72849511A481D6d1467BaF8E6B1bfF40D89`

        let result = json ? json.split("\n") : [];

        result = result.map((item) => {
            let arr = item.split("\t");
            return {
                name: arr[0].trim().toLowerCase(),
                address: arr[1],
                duration: 1
            }
        })

        console.log(result);

        let tuple = []
        for (let i = 0; i < result.length; i++) {
            if (result[i].name.length > 2) {
                tuple.push([result[i].name, result[i].address, result[i].duration]);
            }
        }
        console.log(tuple);
        console.log(signer);

        let registrarContract = new ethers.Contract(Variables().apeRegistrarAddr, Variables().apeRegistrarAbi, signer);
        let tx = await registrarContract.registerTeam(tuple);

    }

    return (
        <div className="bg-white dark:bg-zinc-900 text-black dark:text-white h-full flex flex-col justify-start">
            <div id="about" className="w-full flex justify-center items-start pb-0 pt-8 border-b-2 border-dark700">
                <div className="w-full lg:max-w-[1280px] px-5 md:px-10 lg:px-20 2xl:px-10 lg:rounded-xl ">
                    <div className="flex justify-between items-center w-full">
                        <div>
                            <p className="text-3xl font-bold">Admin Panel</p>
                            <p className="text-md font-normal mt-2 text-dark500">This section is reserved for admins only</p>
                        </div>
                        <div>
                            <button className="bg-main text-white rounded-full px-4 py-3 flex items-center gap-x-1 w-fit" onClick={() => setAddModal(true)}>
                                <p className="text-sm font-semibold">Add</p>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-white text-sm" />
                            </button>
                            <button className="bg-main text-white rounded-full px-4 py-3 flex items-center gap-x-1 w-fit" onClick={() => airdrop()}>
                                <p className="text-sm font-semibold">Airdrop</p>
                                <FontAwesomeIcon icon={['fas', 'arrow-right']} className="text-white text-sm" />
                            </button>
                            {/* <button className="bg-main text-white px-4 py-2 rounded-full" onClick={() => airdrop()}>Airdrop</button> */}
                        </div>
                    </div>

                    <div className="grid grid-cols-4 gap-x-4 mt-8">
                        <div>
                            <p className="text-2xl font-semibold">{reservedTotal}</p>
                            <p className="text-sm font-semibold mt-2 text-gray-500 dark:text-dark500">reserve</p>
                        </div>
                    </div>

                    <div className="flex items-center gap-x-12 w-fit mt-8">
                        <div className={`flex items-center gap-x-2  w-fit pb-2 ${tabSelected == "reserved" ? "border-b-2 border-white" : "border-b-2 border-transparent"}`} onClick={() => setTabSelected("reserved")}>
                            <FontAwesomeIcon icon={['fas', 'tag']} className={` text-sm ${tabSelected == "reserved" ? "text-white" : "text-dark500"}`} />
                            <p className={`text-md font-bold ${tabSelected == "reserved" ? "text-white" : "text-dark500"}`}>Reserved</p>
                        </div>
                    </div>
                </div>

            </div >

            <div>
                <div style={{ display: tabSelected == "reserved" ? "block" : "none" }}>
                    <Lower type={"reserved"} setReservedTotal={setReservedTotal} />
                </div>
            </div>
            <AddReserve isOpen={addModal} setIsOpen={setAddModal} />

        </div>
    );

}

export default Reserve;

const Lower = ({ type, setAirdropsTotal, setReservedTotal, setTeamTotal }) => {
    const [names, setNames] = useState([]);
    const [search, setSearch] = useState("");
    const [searchItems, setSearchItems] = useState([]);

    useEffect(() => {

        callW3Api("/admin/get/reserve", { type: "reserved" }).then((res) => {
            setNames(res);
            setReservedTotal(res.length);
        });
    }, [])

    let headings = ["Name", "Address", "Duration", "Status"]
    let css = {
        div: "w-3/12 flex items-center gap-x-2",
    }

    async function goSearch() {
        if (search == "") {
            setSearchItems([]);
        } else {
            let newItems = names.filter((name) => name.name.toLowerCase().includes(search.toLowerCase()));
            setSearchItems(newItems);
        }
    }

    return (
        <div id="about" className="w-full flex justify-center items-start pb-0 pt-8">
            <div className="w-full lg:max-w-[1280px] px-5 md:px-10 lg:px-20 2xl:px-10 lg:rounded-xl">
                <div className="mt-0">
                    {names.length > 0 ? (
                        <div>
                            <div className="flex justify-between items-center gap-x-4">
                                <div className="w-full">
                                    <UpperTabs search={search} setSearch={setSearch} goSearch={goSearch} />
                                </div>
                                <div className="flex-none my-4">
                                    <button className="bg-main text-white rounded-full px-4 py-2 flex items-center gap-x-1 w-fit " onClick={() => goSearch()}>Search</button>
                                </div>
                            </div>
                            <div className="flex items-center w-full border-b-2 border-dark700 py-4">
                                {headings.map((heading, index) => (
                                    <div key={index} className={css.div}>
                                        <p className="text-dark500 text-sm font-bold">{heading}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="" style={{ display: searchItems.length > 0 ? "none" : "block" }}>
                                <AllNamesMap names={names} />
                            </div>
                            <div className="" style={{ display: searchItems.length > 0 ? "block" : "none" }}>
                                <AllSearchItemsMap names={searchItems} />
                            </div>
                        </div>
                    ) : (
                        (null)
                    )}
                </div>
            </div>
        </div>
    )
}

const AllSearchItemsMap = ({ names }) => {
    const [editReserveOpen, setEditReserveOpen] = useState(false);
    const [editItemSelected, setEditItemSelected] = useState(0);

    return (
        <div>
            {names.map((name, index) => (
                <div key={index}>
                    <NamesMap name={name} index={index} setEditItemSelected={setEditItemSelected} setEditReserveOpen={setEditReserveOpen} />
                    <EditReserve isOpen={editReserveOpen} setIsOpen={setEditReserveOpen} editItem={names[editItemSelected]} />
                </div>
            ))}
        </div>
    )
}

const AllNamesMap = ({ names }) => {
    const [namesLeft, setNamesLeft] = useState(names);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [editReserveOpen, setEditReserveOpen] = useState(false);
    const [editItemSelected, setEditItemSelected] = useState(0);

    async function fetchNext() {
        console.log("fetching next");
        if (namesLeft.length === 0) {
            setHasMore(false);
        } else {
            let newItems = namesLeft.slice(0, 12);
            let remainingNames = namesLeft.slice(12);
            setItems([...items, ...newItems]);
            setNamesLeft(remainingNames);
        }
    }

    useEffect(() => {
        fetchNext();
    }, []);


    return (
        <div>
            {names.length > 0 ? (
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchNext}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    scrollThreshold={0.8}
                >
                    <div className="">
                        {items.map((name, index) => (
                            <div key={index}>
                                <NamesMap name={name} index={index} setEditItemSelected={setEditItemSelected} setEditReserveOpen={setEditReserveOpen} />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (null)}
            <EditReserve isOpen={editReserveOpen} setIsOpen={setEditReserveOpen} editItem={items[editItemSelected]} />
        </div>
    )
}

const NamesMap = ({ name, index, setEditItemSelected, setEditReserveOpen }) => {
    const { address } = useAccount();
    const [domain, setDomain] = useState(name?.address.toLowerCase() == teamAddress.toLowerCase() ? "team" : shortenaddress(name?.address ? name.address : ""));
    const [removeLoading, setRemoveLoading] = useState(false);
    const [removeError, setRemoveError] = useState(false);
    useEffect(() => {
        setDomain(name?.address.toLowerCase() == teamAddress.toLowerCase() ? "team" : shortenaddress(name?.address ? name.address : ""));
    }, [name])

    let css = {
        div: "w-3/12 flex items-center gap-x-2",
        p: ""
    }

    async function removeName() {
        setRemoveLoading(true);
        let result = await callW3Api("/admin/remove/reserve", { name: name.name, signature: localStorage.getItem("accountSignature" + address) });
        if (result == "ok") {
            setRemoveLoading(false);
            window.location.reload();
        } else {
            setRemoveLoading(false);
            setRemoveError(true);
        }
    }

    return (
        <div className="flex items-center w-full border-b-2 border-dark700 py-4">
            <div className={css.div}>
                <p className="text-white font-bold">{name.name}.ape</p>
            </div>
            <div className={css.div}>
                <img src={makeBlockie(name.address)} className="w-5 h-5 rounded-full" />
                <a className="text-main" href={"https://etherscan.io/address/" + name.address} target="_blank">{domain}</a>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} className="text-main" size="xs" />
            </div>
            <div className={css.div}>
                <p className={css.p}>{name.duration} years</p>
            </div>
            <div className="flex items-center gap-x-10">
                <button className="bg-main text-white rounded-full px-4 py-2 flex items-center gap-x-1 w-fit" onClick={() => { setEditItemSelected(index); setEditReserveOpen(true) }} >Edit</button>
                {removeLoading ? (
                    <FontAwesomeIcon icon={['fas', 'circle-notch']} className="text-main animate-spin" />

                ) : (
                    removeError ? (
                        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="text-red-500 dark:text-red-500" />
                    ) : (
                        <div className="w-10 h-10 flex justify-center items-center bg-dark800 rounded-full" onClick={() => removeName()}>
                            <FontAwesomeIcon icon={['fas', 'xmark']} className="text-gray-500 dark:text-dark500" />
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

const UpperTabs = ({ search, setSearch, goSearch }) => {

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            goSearch();
        }
    }

    return (
        <div className="my-4 flex justify-between items-center gap-x-2">
            <div className="onlyvertcenter p-2 pl-4 rounded-xl w-full bg-white dark:bg-dark800 border-2 dark:border border-gray-200 dark:border-dark700">
                <FontAwesomeIcon icon={['fas', 'fa-search']} className="text-gray-500 dak:text-dark500" />
                <input type="text" className="font-semibold text-sm w-full bg-transparent outline-none ml-4 py-1" placeholder="Type to search for a domain" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleKeyDown} />
            </div>
        </div >
    )
}