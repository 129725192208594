const { ethers } = require("ethers");

export function getCloudProvider() {
    let providerUrl = "https://rpc.ankr.com/eth";

    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    return provider
}

export function getCloudProviderFast() {
    let providerUrl = "https://rpc.ankr.com/eth";

    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    return provider
}
